<template>
  <modal
    name="flycal-requests"
    transition="pop-out"
    :adaptive="true"
    @before-open="beforeOpen"
  >
  <table class="fly-dates-popup">
    <thead class="fly-dates-popup__head">
    <tr>
      <th>REQUEST CREATED</th>
      <th>TRIP DATE(S)</th>
      <th>OW/RT?</th>
      <th>AIRCRAFT/SPACES?</th>
      <th>PAX</th>
      <th>LOCATIONS</th>
      <th>RE-ROUTE?</th>
      <th>BUDGET</th>
<!--      <th>FULFILLED?</th>-->
    </tr>
    </thead>
    <tbody class="fly-dates-popup__body">
    <fragment v-for="(item, index) in requests" :key="index">
    <tr>
      <td>{{moment(item.createdOn).format("M/D/YY")}}</td>
      <td>{{item.tripDateText}}</td>
      <td>{{item.directionText}}</td>
      <td><span class="green">{{item.bookingType}}</span></td>
      <td>{{item.passengersNumber}}</td>
      <!-- <td width="300" >
        <span v-for="(loc, index) in item.outLocations" :key="index"> {{"OUT: " + loc.displayName.split(",")[0] + " (" + loc.airport_Id + ")"}}
        <br>
        </span>
        <span v-for="(loc, index) in item.inLocations" :key="index">
          {{"IN: "  + loc.displayName.split(",")[0] + " (" + loc.airport_Id + ")"}}
        <br>
        </span>
      </td> -->
      <td width="300" >
        <span>
          {{item.locationsText}}
        </span>
      </td>
      <td>{{item.departureReroute}} <br>
        {{item.arrivalReroute}}
      </td>
      <td>
        {{"$"+item.budgetGoal}}
      </td>
<!--      <td>-->
<!--        {{item.paid ? 'YES' : 'NO'}}-->
<!--      </td>-->
    </tr>
    <tr class="spacer"></tr>
    </fragment>
    </tbody>
  </table>
  <table class="fly-popup-small">
    <thead class="fly-popup-small__head">
    <tr>
      <th>input date</th>
      <th>TRIP DATE(S)</th>
      <th>LOCATIONS</th>
    </tr>
    <tr class="spacer"></tr>
    </thead>
    <tbody class="fly-popup-small__body">
    <fragment v-for="(item, index) in requests" :key="index">
    <tr>
      <td>{{moment(item.createdOn).format("M/DD/YY")}}</td>
      <td>{{item.tripDateText}}</td>
      <!-- <td width="300" >
        <span v-for="(loc, index) in item.outLocations" :key="index"> {{"OUT: " + loc.displayName.split(",")[0] + " (" + loc.airport_Id + ")"}}
        <br>
        </span>
        <span v-for="(loc, index) in item.inLocations" :key="index">
          {{"IN: "  + loc.displayName.split(",")[0] + " (" + loc.airport_Id + ")"}}
        <br>
        </span>
      </td> -->
      <td width="300" >
        <span>
          {{item.locationsTextMobile.split(' - ')[0]}} <br> {{item.locationsTextMobile.split(' - ')[1]}}
        </span>
      </td>
    </tr>
      <tr class="button-row">
        <td colspan="3">
          <button class="flyCalendar-popup__table-button" @click="()=>showDetail(item)">Detail</button>
        </td>
      </tr>
    <tr class="spacer"></tr>
    </fragment>
    </tbody>
  </table>
    <div class="flyCal__small-detail">
        <button class="flyCalendar-popup__table-rowMobile-button js-flyCalendar-submenu-close" @click="hideDetail">
          <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000" d="M16.484 22.567a.745.745 0 0 0 .54.223.763.763 0 0 0 .54-1.302L8.073 12l9.489-9.489a.764.764 0 0 0-1.08-1.079L6.457 11.46a.764.764 0 0 0 0 1.08l10.028 10.027z"></path>
          </svg>
          <span>Back</span>
        </button>
        <div class="flyCalendar-popup__table-rowMobile-table">
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">INPUT DATE</div>
            <div class="js-created-on flyCalendar-popup__table-rowMobile-right">{{currentItem && moment(currentItem.createdOn).format("M/D/YY")}}</div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">TRIP DATE(S)</div>
            <div class="js-trip-date flyCalendar-popup__table-rowMobile-right">{{currentItem && currentItem.tripDateText}}</div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">OW/RT?</div>
            <div class="js-ow-rt-mode flyCalendar-popup__table-rowMobile-right">{{currentItem && currentItem.directionText}}</div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">AIRCRAFT/SPACES?</div>
            <div class="js-aircraft-space-mode flyCalendar-popup__table-rowMobile-right">{{currentItem && currentItem.bookingType}}</div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">PAX</div>
            <div class="js-space-qu flyCalendar-popup__table-rowMobile-right">{{currentItem && currentItem.passengersNumber}}</div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">LOCATIONS</div>
            <div class="flyCalendar-popup__table-rowMobile-right">
              <span>
                {{currentItem && currentItem.locationsText}}
              </span>
            </div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">RE-ROUTE?</div>
            <div class="flyCalendar-popup__table-rowMobile-right">
              {{currentItem && currentItem.departureReroute}} <br>
              {{currentItem && currentItem.arrivalReroute}}
            </div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">BUDGET</div>
            <div class="js-budget-goal flyCalendar-popup__table-rowMobile-right">{{currentItem && "$"+currentItem.budgetGoal}}</div>
          </div>
          <div class="flyCalendar-popup__table-rowMobile-row">
            <div class="flyCalendar-popup__table-rowMobile-left">FULFILLED?</div>
            <div class="js-fulfilled flyCalendar-popup__table-rowMobile-right">{{currentItem && currentItem.paid ? 'YES' : 'NO'}}</div>
          </div>
        </div>
    </div>
    <div class="modal__close fly-popup-small__close" @click="closePopup">
      <SvgIcon name="close-white"/>
    </div>
  </modal>
</template>

<script>
import './FlyDatesPopup.styl';
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import { Fragment } from 'vue-fragment'
import moment from 'moment';

export default {
  name: 'FlyDatesPopup',
  data: () => ({
    requests: [],
    currentItem: null,
    }),
  components: {
    SvgIcon,
    Fragment,
  },
  methods: {
    beforeOpen (event) {
      this.requests = event.params
      this.currentItem = this.requests[0]
    },
    moment,
    showDetail (item) {
      this.currentItem = item
      document.querySelector('.flyCal__small-detail').classList.add('show')
    },
    hideDetail () {
      this.currentItem = null
      document.querySelector('.flyCal__small-detail').classList.remove('show')
    },
    opened () {
      if (document.querySelector('html').classList.contains('d-mobile')) {
        const container = document.querySelector('.vm--container')
        const popup = document.querySelector('.bookRoutesPopup')

        container.classList.add('js-bookRoutesPopup')
        document.querySelector('body').appendChild(container)
        setTimeout(() => {
          popup.style.opacity = 1
        }, 300)
      }
    },
    async closePopup () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
